
























































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import {
  mansekiStatus,
  isWaitingList,
  isCourseApplicationMethod,
  courseTimeStatus,
  isReservedStatus,
  isReservedStatus2,
  isReservedStatus3,
  isCancelNoteStatus,
} from '../../../plugins/helper';
import { WPPost, Course } from '../../../../types/typescript-axios/api';

@Component
export default class CourseHistoryBox extends Vue {
  @Prop({ default: null })
  private course!: any;

  get isWaitngListStatus(): boolean {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return isWaitingList(this.course);
  }

  get isManseki(): boolean {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return mansekiStatus(this.course);
  }

  get isApplicationMethod(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return isCourseApplicationMethod(this.course);
  }

  get timeStatus(): number {
    // 申込み受付期間前：0
    // 申込み受付期間中：1
    // 申込み期間終了：2
    // 開催終了：3
    return courseTimeStatus(this.course);
  }

  private get isStatus1(): number {
    // -1: 未申し込み
    // 0: 申込受付
    // 1: キャンセル
    // 2: キャンセル待ち
    return isReservedStatus(this.course);
  }

  private get isStatus2() {
    // 講座の予約ステータス2
    // -1: 未申し込み
    // 0: -
    // 1: キャンセル待ち繰り上げ
    // 2: 繰り上げ確認中
    // 3: キャンセル料発生
    // 4: 無断キャンセル
    // 5: キャンセル待ち辞退
    return isReservedStatus2(this.course);
  }

  private get isStatus3() {
    // 講座の支払い状況
    // -1: 未申し込み
    // 0: -
    // 1: 未入金
    // 2: 入金済
    return isReservedStatus3(this.course);
  }

  get statusText(): string {
    if (this.isStatus1 === 1) { // キャンセル
      if ( this.isStatus3 === 1 ) { // キャンセル料未払い
        return 'キャンセル<br><b class="has-text-danger">※キャンセル料をお支払いいただいておりません。</b>';
      }
      return 'キャンセル';
    }

    if (this.timeStatus === 3) { // 講座終了
      if (this.isStatus1 === 0) { // 申込受付
        return '受講済';
      }
      if (
        this.isStatus1 === 2 && // キャンセル待ち
        this.isStatus2 === 2 // 繰り上げ確認中
      ) {
        return '終了';
      }
      return 'キャンセル待ち';
    }

    if (this.isStatus1 === 2) { // キャンセル待ち
      if (this.isStatus2 === 2) { // 繰り上げ確認中
        return 'キャンセル待ち<br><b>※メールが送信されていますので、本講座の参加確認手続きを行ってください。</b>';
      }
      return 'キャンセル待ち';
    }

    return '申込み中';
  }

  private get isNoteStatus(): number {
    return isCancelNoteStatus(this.course);
  }
}
