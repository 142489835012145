



import { Component, Vue, Emit } from 'vue-property-decorator';
import VueRouter from 'vue-router';
import store from '../../../store';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { WPPost, Course } from '../../../../types/typescript-axios/api';

import Pagenation from '../../../components/contents/pangenation/Pangenation.vue';
import Error from '../../../components/contents/Error.vue';
import CourseHistoryBox from '@/components/contents/course/CourseHistoryBox.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getDatas(to: VueRouter) {
  let status: number = 200;
  const url: string = '/wp-json/moc/account/course';

  const $to: any = to;

  const $query: any = $to.query;

  let offset: number = 0;
  const output: number = 5;

  if (typeof $query !== 'undefined' && typeof $query.p !== 'undefined') {
    offset = output * (Number($query.p) - 1);
    if (offset < 0) {
      offset = 0;
    }
  }

  const queryName: string = '';

  const params: any = {
    orderby: 'day',
    order: 'desc',
    offset,
    output,
  };

  const res = await axios.get<AxiosResponse>(url, {params});
  status = res.status;
  const newData: any = res.data;
  const contentsData = newData.courses;
  const total = newData.total;

  return {
    status,
    contentsData,
    total,
    res,
    queryName,
    $to,
  };
}

@Component({
  components: {
    Pagenation,
    CourseHistoryBox,
  },
})
export default class AccountCourse extends Vue {
  private total: any = '';
  private courses: any = '';
  private paramsName: number = 0;
  private link: string = '/account/course/?p=';
  private status = 200;

  get pageBack(): string|null {
    return window.sessionStorage.getItem('fromPath');
  }

  get queryName(): number {
    if (typeof this.$route.query.p === 'undefined') {
      return 1;
    } else {
      return Number(this.$route.query.p);
    }
  }

  private back() {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
  }

  @Emit()
  private dateFilter(date: string) {
    const d: Date = new Date(date);
    const dayArr = ['日', '月', '火', '水', '木', '金', '土'];
    const day = dayArr[d.getDay()];
    return `${d.getFullYear()}年${d.getMonth() + 1}月${d.getDate()}日`;
  }

  private async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      next((vm: {
        courses: WPPost[],
        paramsName: number,
        total: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.courses = data.contentsData;
        vm.total = data.total;
        vm.paramsName = Number(data.$to.query.p);
      });
    } else {
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  private async beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      const $to: any = to;
      this.courses = data.contentsData;
      this.total = data.total;
      this.paramsName = Number($to.query.p);
    }
    next();
  }
}
